@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #292524;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {
  .bg {
    @apply p-8 bg-stone-900 w-full h-full rounded-xl min-h-[700px] content-start overflow-y-scroll;
  }
  .pill {
    @apply px-4 py-2 border rounded-lg cursor-pointer hover:bg-stone-800 md:justify-items-start justify-items-center bg-stone-900 gap-x-6 items-center;
  }
  .info {
    @apply grid gap-6 mt-8 text-sm md:gap-8 md:grid-cols-3 items-center;
  }
  .card {
    @apply p-4 text-sm border rounded-lg cursor-pointer h-fit hover:bg-stone-800 border-stone-400 bg-stone-900;
  }
  .selected-card {
    @apply p-4 text-sm border rounded-lg cursor-pointer h-fit hover:bg-stone-800 bg-opacity-10 border-yellow-600 bg-yellow-900;
  }

  .input {
    @apply bg-stone-900 rounded-md px-2 py-2 outline-primary focus:outline-none text-white;
  }

  .icon {
    @apply w-5 h-5 text-stone-200 hover:text-stone-400 cursor-pointer;
  }
  .select {
    @apply px-3 pt-3 pb-2 bg-transparent border-b border-yellow-600 outline-none cursor-pointer;
  }

  .form-element {
    @apply grid grid-cols-3 items-center;
  }

  .form-label {
    @apply text-stone-200 text-sm;
  }

  .form-input {
    @apply rounded-md px-2 py-2 border border-stone-600 bg-transparent focus:outline-none focus:ring-1 ring-yellow-600 text-stone-200;
  }

  .form-input-disabled {
    @apply rounded-md px-2 py-2 col-span-2 cursor-not-allowed bg-stone-800;
  }

  .form-checkbox {
    @apply flex space-x-4 items-center;
  }

  .checkbox {
    @apply accent-yellow-600 w-4 h-4 cursor-pointer;
  }

  .checkbox-label {
    @apply font-semibold mx-2 w-32;
  }

  .btn-primary {
    @apply py-2 px-4 h-fit text-center bg-yellow-600 hover:bg-yellow-800 text-white rounded-md cursor-pointer;
  }

  .btn-secondary {
    @apply py-2 px-4 h-fit text-center bg-stone-600 hover:bg-stone-700 text-white rounded-md cursor-pointer;
  }

  .btn-tertiary {
    @apply py-2 px-4 text-center border-primary hover:bg-yellow-800 text-white rounded-md cursor-pointer;
  }

  .btn-warning {
    @apply py-2 px-4 h-fit text-center bg-red-900 hover:bg-red-800 text-white rounded-md cursor-pointer;
  }

  .btn-loading {
    @apply flex items-center justify-center cursor-progress py-2 px-4 h-fit text-center bg-stone-600 rounded-md;
  }

  .btn-radio {
    @apply px-4 py-2 font-semibold text-center border-2 border-green-700 rounded-md;
  }

  .hr {
    @apply border border-stone-600 rounded-md;
  }

  .hr-primary {
    @apply border border-yellow-600 rounded-md;
  }

  .loading-container {
    @apply flex items-center justify-center w-full h-full bg-stone-900;
  }
}
